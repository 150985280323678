import { Link } from "react-router-dom"
import { useIsMobile } from "../hooks/IsMobileSize.hook"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faPhone } from "@fortawesome/free-solid-svg-icons" 
import { faX } from "@fortawesome/free-solid-svg-icons" 
import { faLocationPin } from "@fortawesome/free-solid-svg-icons"
import { faEnvelope } from "@fortawesome/free-solid-svg-icons"
import Image from "../images/logo.png"
import { useEffect, useState } from "react"

export const NavBar = () => {
    const { isMobile } = useIsMobile()
    const [showMenu, setShowMenu] = useState(false);

    useEffect(()=> {
        if (isMobile === false){
            setShowMenu(true)
        } else {
            setShowMenu(false)
        }
    },[isMobile])

    return(
    <>
        <nav className="bg-white w-full top-0 start-0 shadow-md">
            <div className="flex md:flex-row flex-col md:space-x-20 space-y-3 py-10">

                <div className="flex md:w-full">
                </div>
                
                <div className="flex flex-col md:w-full items-center justify-center font-Montserrat">
                    <div className="flex flex-row">
                        <div>
                            {!showMenu && (<FontAwesomeIcon onClick={() => setShowMenu(true)} icon={faBars} className="cursor-pointer fa-lg md:hidden my-[18px]"/>)}   
                            {showMenu && (<FontAwesomeIcon onClick={() => setShowMenu(false)} icon={faX} className="cursor-pointer fa-lg md:hidden my-[18px]"/>)}  
                        </div>
                        <div>
                            <Link to="/" className="px-5 flex flex-row items-center">
                                <img src={Image} alt="logo" width={isMobile?250:384} height={isMobile?64:101}/>
                            </Link>
                        </div>
                    </div>
                    <div>
                        {showMenu && ( <ul className="flex flex-wrap md:flex-row flex-col gap-y-5 md:gap-x-5 text-gray-500 justify-center hover:text-gray-200 p-8 font-semibold text-lg text-center">
                            <li className="hover:text-[#ac5c84]">
                                <Link to="/">
                                    HOME
                                </Link>
                            </li>
                            <li className="hover:text-[#ac5c84]">
                                <Link to="/services" state={{from:""}}>
                                    SERVICES
                                </Link>
                            </li>
                            <li className="hover:text-[#ac5c84]">
                                <Link to="/new-patients">
                                    PATIENT INFO
                                </Link>
                            </li>
                            <li className="hover:text-[#ac5c84]">
                                <Link to="/contact">
                                    CONTACT
                                </Link>
                            </li>
                        </ul>)}
                    
                    </div>
                </div>

                <div class="flex md:w-full justify-end items-center font-Montserrat">
                    <div class="flex flex-col space-y-4 pr-[15%] items-center">
                        <div>
                            <ul>
                                <li>
                                    <div>
                                        <a href="tel:+9057375559">
                                            <FontAwesomeIcon icon={faPhone} style={{color: "#97207e",}}/>
                                            <text className="px-2 text-md text-gray-500">(905) 737-5559</text>
                                        </a>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <FontAwesomeIcon icon={faLocationPin} style={{color: "#97207e",}}/>
                                        <text className="px-2 text-md text-gray-500"> 63 Mclean Ave, Collingwood</text>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <FontAwesomeIcon icon={faEnvelope} style={{color: "#97207e",}}/>
                                        <text className="px-2 text-md text-gray-500">info@happyfamilywellness.ca</text>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="md:py-0 py-3">
                            <Link to="https://happyfamilywellnessclinic.clinicsense.com/book/" target="_blank">
                                <button className="bg-white hover:bg-gradient-to-r from-[#be7e9e] to-[#cc98b4] hover:text-white text-[#942d64] px-5 py-2 rounded-lg border border-black border-opacity-10 font-medium text-lg">
                                    ONLINE BOOKING
                                </button> 
                            </Link>
                        </div>
                    </div>    
                </div>
            </div>
        </nav>
    </>
    )
}


